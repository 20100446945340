// import w/ object destructuring doesn't benefit from tree shaking
import partialRight from 'ramda/es/partialRight';
import curry from 'ramda/es/curry';
import _ from 'ramda/es/__';
import unary from 'ramda/es/unary';
import bigpicture from 'bigpicture';
import Glide from '@glidejs/glide';

import {
    find,
    findAll,
    findChild,
    findChildren,
    clone,
    remove,
    getParent,
    getHeight,
    getValue,
    hasClass,
    addClass,
    removeClass,
    toggleClass,
    getAttribute,
    setHTML,
    setStyle,
    scrollTo,
    on,
    once,
    onAll,
    click,
    debounce,
    ready,
    preventDefault,
    callFnWithElementsIfExist
} from 'flightdom';

import router from './router';
import {
    onClick,
    onAllClick,
    showNetworkErr,
    ajax,
    updateMiniCart,
    getModalInitialTransform
} from './helpers';

/**
 * Init mobile menu toggle & submenu fold/unfold
 * @param { HTMLElement } menuToggle Humburger icon
 * @param { HTMLElement } menu
 * @param { NodeList } entriesWithChildren Menu list els that contain ul
 */
function initMenu(menuToggle, menu, parentMenuList, entriesWithChildren, overlay) {
    const addClassOverlay = curry(addClass)(overlay);
    const removeClassOverlay = curry(removeClass)(overlay);

    const timerOne = debounce();
    const timerTwo = debounce();
    let timerTwoRef;

    const showOverlay = () => {
        ['active', 'menu'].forEach(addClassOverlay);
        timerOne(() => addClassOverlay('visible'), 400);
        clearTimeout(timerTwoRef);
    };

    const hideOverlay = () => {
        timerOne(() => removeClassOverlay('visible'), 200);
        timerTwoRef = timerTwo(() => ['active', 'menu'].forEach(removeClassOverlay), 800);
    };

    onClick(menuToggle, () => {
        if (!hasClass(menuToggle, 'active')) showOverlay();
        else hideOverlay();

        [menuToggle, menu].forEach(el => toggleClass(el, 'active'));
    });

    // activate overlay for desktop
    if (!window.matchMedia('(hover: none)').matches) {
        onAll(parentMenuList, 'mouseover', showOverlay);
        onAll(parentMenuList, 'mouseout', hideOverlay);
    }

    // manage state for touch devices
    onAllClick(entriesWithChildren, (e) => {
        if (!window.matchMedia('(hover: none)').matches) return;
        const target = getParent(e.target);

        // prevents click only on parent element
        if (hasClass(target, 'menu-item-has-children')) preventDefault(e);

        if (!hasClass(target, 'active')) {
            entriesWithChildren.forEach(el => removeClass(el, 'active'));
            toggleClass(target, 'active');
            return;
        }

        removeClass(target, 'active');
    });
}

/**
 * Init miniCart toggle on its icon hover
 * @param { HTMLElement } miniCartIcon
 * @param { HTMLElement } miniCart
 */
function initMiniCart(miniCartIcon, miniCart) {
    let timer;

    const showMiniCart = () => addClass(miniCart, 'active');
    const hideMiniCart = () => removeClass(miniCart, 'active');

    on(miniCartIcon, 'mouseenter', () => {
        clearTimeout(timer);
        showMiniCart();
    });

    on(miniCartIcon, 'mouseleave', () => {
        timer = setTimeout(hideMiniCart, 1000);
    });

    on(miniCart, 'mouseenter', () => clearTimeout(timer));
    on(miniCart, 'mouseleave', () => {
        timer = setTimeout(hideMiniCart, 1000);
    });

    // event on miniCart so it's still binded after minicart update
    on(miniCart, 'click', (e) => {
        if (hasClass(e.target, 'delete')) {
            const productKey = getAttribute(e.target, 'data-item-key');

            ajax('POST', '/wp/wp-admin/admin-ajax.php', {
                action: 'update_cart',
                key: productKey,
                update: 'remove_item'
            }, showNetworkErr, () => updateMiniCart(miniCartIcon, miniCart));
        }
    });
}

/**
 * Display a live search in a small window under the search bar
 * @param { HTMLInputElement } searchInput search bar
 * @param { HTMLElement} searchResults
*/
function initMiniSearch(searchInput, searchResults) {
    const showResults = () => addClass(searchResults, 'active');
    const hideResults = () => removeClass(searchResults, 'active');
    const timer = debounce();

    on(searchInput, 'input', (e) => {
        timer(() => {
            const searchTerm = getValue(e.target);

            if (searchTerm.length < 3) {
                hideResults();
                return;
            }

            ajax('POST', '/wp/wp-admin/admin-ajax.php', { action: 'ajax_search', search: searchTerm }, showNetworkErr, (res) => {
                setHTML(searchResults, res);
                showResults();
            });
        }, 200);
    });
}

/**
 * Init lightbox and open it on click
 * @param { NodeList } imgs
 */
function initLightbox(imgs) {
    onAllClick(imgs, e => bigpicture({ el: e.target }));
}

/**
 * Open lightbox for youtube videos on btns click
 * @param { NodeList } videoBtns
 */
function initYoutubeBox(videoBtns) {
    onAllClick(videoBtns, (e) => {
        bigpicture({
            el: e.target,
            ytSrc: getAttribute(e.target, 'data-videoid')
        });
    });
}

/**
 * Get youtube video's cover image and add it to player (player is a styled div)
 * @param { NodeList } players
 */
function initYoutubeEmbed(players) {
    players.forEach((el) => {
        const videoId = (getAttribute(el, 'data-videoid'));
        setStyle(el, 'background-image', `url(https://img.youtube.com/vi/${videoId}/sddefault.jpg)`);
    });
}

/**
 * Init collapsible and toggle elements w/ transition
 * @param { NodeList } accordionHeaders
 * @param { NodeList } accordionCollapsibles
 */
function initCollapsible(accordionHeaders, accordionCollapsibles) {
    onAllClick(accordionHeaders, (e) => {
        const collapsible = getParent(e.target);

        let allCollapsed = true;

        // don't open anything as the target was the opened item
        if (hasClass(collapsible, 'active')) {
            removeClass(collapsible, 'active');
            return;
        }

        // check if any was opened && close all opened elements
        accordionCollapsibles.forEach((el) => {
            if (hasClass(el, 'active')) allCollapsed = false;
            removeClass(el, 'active');
        });

        // don't wait for collapse transition if none was opened
        if (allCollapsed) addClass(collapsible, 'active');
        else setTimeout(() => addClass(collapsible, 'active'), 450);
    });
}

/**
 * Init tabs
 * @param { NodeList } panelTabs
 * @param { NodeList } panels
 */
function initTabs(panelTabs, panels) {
    const addActive = unary(partialRight(addClass, ['active']));
    const removeActive = unary(partialRight(removeClass, ['active']));

    onAllClick(panelTabs, (e) => {
        preventDefault(e);

        panelTabs.forEach(removeActive);
        addActive(e.target);

        panels.forEach(removeActive);
        addActive(find(e.target.hash));
    });
}

/**
 * Init newsletter
 * @param { HTMLFormElement } form
 * @param { HTMLInputelement } email
 * @param { HTMLElement } error
 * @param { HTMLElement } success
 */
function initNewsletterSubscription(form, email, error, success) {
    on(form, 'submit', (e) => {
        preventDefault(e);
        const subscriberEmail = getValue(email);

        ajax('POST', '/wp/wp-admin/admin-ajax.php', { action: 'add_newsletter_subscription', email: subscriberEmail }, showNetworkErr, (res) => {
            const jsonRes = JSON.parse(res);
            setStyle(error, 'display', 'none');

            if (jsonRes.error) {
                setHTML(error, jsonRes.error);
                setStyle(error, 'display', 'block');
                return;
            }

            setHTML(success, jsonRes.success);
            setStyle(success, 'display', 'block');
        });
    });
}

/**
 * Trigger a tab & scroll to it when trigger link is clicked
 * @param { HTMLAnchorElement } triggerLink
 * @param { HTMLElement } targetTab
 */
function initGoToTab(triggerLink, targetTab) {
    onClick(triggerLink, (e) => {
        preventDefault(e);

        click(targetTab);
        scrollTo(targetTab, true);
    });
}

/**
 * Morph white paper modal from btn to full modal
 * @param { HTMLElement } body
 * @param { HTMLElement } btn
 * @param { HTMLElement } modal
 * @param { HTMLElement } overlay
 */
function morphWithePaperModal(body, btn, modal, overlay) {
    const addClassModal = curry(addClass)(modal);
    const removeClassModal = curry(removeClass)(modal);

    const closeModal = (initialState) => {
        // fade modal once btn is operational again & over modal
        once(btn, 'transitionend', () => {
            ['visible', 'active'].forEach(unary(removeClassModal));
            removeClass(btn, 'active');
            setStyle(modal, 'transform', '');
        });

        // once modal faded, return it to totally inactive state
        once(modal, 'transitionend', () => removeClass(overlay, 'active'));

        removeClass(body, 'noscroll');
        removeClass(overlay, 'visible');
        removeClassModal('expanded');
        setStyle(modal, 'transform', initialState);
        removeClassModal('fading');
        removeClass(btn, 'hidden');
    };

    on(btn, 'click', () => {
        // Modal is still hidden (0 opacity) but occupies space as its final state
        // Allowing for correct computation
        addClass(body, 'noscroll');
        addClass(modal, 'active');

        // Once btn has finised fading, start modal morph
        once(btn, 'transitionend', () => {
            setStyle(modal, 'transform', 'translate(0) scale(1)');
            addClass(overlay, 'visible');
            addClassModal('expanded');
        });

        const initialState = getModalInitialTransform(btn, modal);
        setStyle(modal, 'transform', initialState);

        // Give the browser just enough time to position the modal
        // then set visible
        setTimeout(() => ['fading', 'visible'].forEach(unary(addClassModal)), 100);
        addClass(btn, 'hidden');
        addClass(overlay, 'active');

        once(findChild(modal, '.close-modal'), 'click', () => closeModal(initialState));
    });

    on(findChild(modal, 'form'), 'submit', (e) => {
        e.preventDefault();
    });
}

/**
 * Initialise white paper modal w/ event listeners
 * @param { HTMLElement } body
 * @param { HTMLElement } btn
 * @param { HTMLElement } modal
 * @param { HTMLElement } overlay
 */
function iniWhitePaperModal(body, btn, modal, overlay) {
    morphWithePaperModal(body, btn, modal, overlay);
    on(findChild(modal, 'form'), 'submit', (e) => {
        e.preventDefault();

        const success = findChild(modal, '.success');
        const error = findChild(modal, '.error');
        const email = getValue(findChild(modal, '#white-paper-email'));
        const validateBtn = findChild(modal, 'form button');

        if (!email) return;
        addClass(validateBtn, 'loading');

        ajax('POST', '/wp/wp-admin/admin-ajax.php', { action: 'send_white_paper_email', email }, () => {
            removeClass(validateBtn, 'loading');
            setStyle(error, 'display', 'block');
        }, () => {
            removeClass(validateBtn, 'loading');
            setStyle(success, 'display', 'block');
        });
    });
}

/**
 * Hide category description if too tall, place "read more" link and plug behaviours
 * @param { HTMLElement } categoryHeader
 */
function initCategoryReadMore(categoryHeader) {
    const categoryHeaderClone = categoryHeader.cloneNode(true);
    const mask = findChild(categoryHeader, '.mask');
    const readMore = findChild(categoryHeader, '.read-more');

    setStyle(categoryHeaderClone, 'visibility', 'hidden');
    setStyle(categoryHeaderClone, 'position', 'absolute');
    setStyle(categoryHeaderClone, 'max-height', 'unset');
    getParent(categoryHeader).appendChild(categoryHeaderClone);

    // Wait for fonts to be loaded before computing text position
    document.fonts.ready.then(() => {
        const fullHeight = categoryHeaderClone.offsetHeight;
        const heightDifference = categoryHeaderClone.offsetHeight - categoryHeader.offsetHeight;

        // Return if no real difference
        if (heightDifference < 5) return;

        setStyle(mask, 'display', 'block');
        setStyle(mask, 'top', `-${heightDifference + 79}px`); // diff height + own height (minus one to compensate space w/ read-more)

        setStyle(readMore, 'display', 'inline');
        setStyle(readMore, 'top', `-${heightDifference + 100}px`); // diff height + mask height + 20px of margin

        onClick(readMore, () => {
            setStyle(readMore, 'opacity', 0);
            setStyle(mask, 'opacity', 0);
            setStyle(categoryHeader, 'max-height', `${fullHeight}px`);

            // remove mask after transition to allow for text selection and links navigation
            setTimeout(() => setStyle(mask, 'display', 'none'), 1000);
        });

        remove(categoryHeaderClone);
    });
}

/**
 * Initialise sliders plugin and adjust items height
 * @param { NodeList } sliders
 */
function initProductsSliders(sliders) {
    const equalizeHeight = (nodes) => {
        let height = 0;
        const setHeight = curry(setStyle)(_, 'height', _);

        // reset element's height
        nodes.forEach(node => setHeight(node, ''));

        // compute max height
        nodes.forEach((node) => {
            height = Math.max(height, node.offsetHeight);
        });

        // apply new height
        nodes.forEach(node => setHeight(node, `${height}px`));
    };

    sliders.forEach((el) => {
        const glide = new Glide(el, {
            autoplay: 5000,
            gap: 34,
            hoverpause: true,
            perView: 5,
            type: 'carousel',
            breakpoints: {
                991: {
                    perView: 2
                }
            }
        });

        glide.on('build.after', () => {
            const nodes = findChildren(glide.selector, '.wrap');
            equalizeHeight(nodes);
        });

        glide.mount();
    });
}

/**
 * Initialise company domains reveal
 * @param { HTMLElement } showMoreDomainsBtn
 * @param { HTMLElement } companyDomainsContainer
 */
function initShowAllCompanyDomains(showMoreDomainsBtn, companyDomainsContainer) {
    if (window.innerWidth > 769) return;

    const companyDomainsContainerClone = clone(companyDomainsContainer);
    setStyle(companyDomainsContainerClone, 'visibility', 'hidden');
    setStyle(companyDomainsContainerClone, 'position', 'absolute');
    getParent(companyDomainsContainer).appendChild(companyDomainsContainerClone);

    const setHeightsWhenImagesAreLoaded = () => {
        const foldedHeight = getHeight(companyDomainsContainerClone, 'offsetHeight');
        const companyDomainsContainerChildren = findChildren(companyDomainsContainer, '.domain');

        setStyle(companyDomainsContainer, 'height', `${foldedHeight}px`);
        findChildren(companyDomainsContainerClone, '.domain').forEach(el => setStyle(el, 'display', 'block'));
        const unfoldedHeight = companyDomainsContainerClone.offsetHeight;

        onClick(showMoreDomainsBtn, (e) => {
            addClass(e.target, 'fading');
            setStyle(companyDomainsContainer, 'height', `${unfoldedHeight}px`);
            companyDomainsContainerChildren.forEach(el => setStyle(el, 'display', 'block'));

            setTimeout(() => {
                addClass(e.target, 'faded');
                companyDomainsContainerChildren.forEach(el => setStyle(el, 'opacity', '1'));
            }, 200);
        });
    };

    let loadingImages = 0;
    const domainsImages = findChildren(companyDomainsContainerClone, '.domain img');

    domainsImages.forEach((img, i) => {
        // if all images have been checked and none is loading, compute height
        if (i === domainsImages.length - 1 && !loadingImages) {
            setHeightsWhenImagesAreLoaded();
            return;
        }

        if (img.complete) return;
        loadingImages++;

        once(img, 'load', () => {
            if (--loadingImages === 0) setHeightsWhenImagesAreLoaded();
        });
    });
}

ready(() => {
    router({
        home: 'home',
        'single-product': 'single-product',
        cart: 'woocommerce-cart',
        blog: 'single-post',
        account: 'woocommerce-account',
        contact: ['page-template-page-contact', 'page-template-page-contact-pro', 'page-template-page-ambassadors']
    })
    .catch(console.error);

    initMenu(find('#toggle-menu'), find('#header-menu'), findAll('#header-menu .menu-item-has-children'), findAll('#header-menu .menu-item-has-children'), find('#overlay'));
    initMiniSearch(find('#searchform input'), find('.mini-search-results'));
    initMiniCart(find('#mini-cart-icon'), find('#mini-cart'));
    initCollapsible(findAll('.collapsible h1'), findAll('.collapsible'));
    initTabs(findAll('.tabs a'), findAll('.panel'));
    initYoutubeEmbed(findAll('.youtube-player'));
    initProductsSliders(findAll('.glide'));
    updateMiniCart(find('#mini-cart-icon'), find('#mini-cart'));

    callFnWithElementsIfExist(initLightbox, [findAll('.lightbox')]); // simple lightbox
    callFnWithElementsIfExist(initYoutubeBox, [findAll('.youtube')]);
    callFnWithElementsIfExist(initCategoryReadMore, [find('.category.container header')]);
    callFnWithElementsIfExist(initShowAllCompanyDomains, [find('#show-more-domains'), find('#company-domains .container')]);
    callFnWithElementsIfExist(iniWhitePaperModal, [find('body'), find('#white-paper-btn'), find('#white-paper-modal'), find('#overlay')]);
    callFnWithElementsIfExist(initNewsletterSubscription, [find('#newsletter-signup form'), find('#newsletter-signup input'), find('.company-news .error'), find('.company-news .success')]);
    callFnWithElementsIfExist(initGoToTab, [find('#reviews-link'), find('#reviews-tab')]);
    callFnWithElementsIfExist(initGoToTab, [find('#sizes-guide-link'), find('#sizes-guide-tab')]);
});
