import curry from 'ramda/es/curry';
import _ from 'ramda/es/__';

import {
    findChildren,
    getParent,
    setAttribute,
    getCoordinates,
    getWidth,
    getHeight,
    setHTML,
    on,
    onAll,
    find,
    addClass,
    findChild,
    removeClass
} from 'flightdom';

const onClick = curry(on)(_, 'click', _);
const onAllClick = curry(onAll)(_, 'click', _);
const getGrandParent = el => getParent(getParent(el));

/**
 * Show a flash notification message
 * @param { String } type
 * @param { String } text
 */
function showFlashMessage(type, text) {
    const message = document.createElement('div');
    ['flash-message', 'closed', type].forEach(className => addClass(message, className));

    setHTML(message, `<p>${text}</p><button>X</button>`);
    const button = findChild(message, 'button');
    find('body').append(message);

    setTimeout(() => removeClass(message, 'closed'), 10);
    on(button, 'click', () => addClass(message, 'closed'));
}

function showNetworkErr() {
    showFlashMessage('success', 'Une erreur s’est produite. Vérifiez que vous êtes bien connecté à internet.');
}

/**
 * Send XMLHttpRequest and call either error or success callback
 * @param { String } method
 * @param { String } url
 * @param { Object } body
 * @param { Function } errCallback
 * @param { Function } successCallback
 */
function ajax(method, url, body, errCallback, successCallback) {
    const xhr = new XMLHttpRequest();
    const form = body ? new FormData() : null;

    xhr.onload = () => {
        if (xhr.status !== 200) {
            errCallback(xhr.status);
            return;
        }

        successCallback(xhr.response);
    };

    xhr.onerror = errCallback;
    xhr.open(method, url);

    if (body) {
        Object.keys(body).forEach(key => form.append(key, body[key]));
        xhr.send(form);
    }

    else xhr.send();
}

/**
 * Compute needed modal transform to adopt btn's size & position
 * @param { HTMLElement } btn
 * @param { HTMLElement } modal
 * @return { String } CSS transform's translate property
 */
function getModalInitialTransform(btn, modal) {
    const btnWidth = getWidth(btn);
    const btnHeight = getHeight(btn);
    const btnTop = getCoordinates(btn, 'top');
    const btnLeft = getCoordinates(btn, 'left');

    const modalWidth = getWidth(modal);
    const modalHeight = getHeight(modal);
    const modalTop = getCoordinates(modal, 'top');
    const modalLeft = getCoordinates(modal, 'left');

    const scaleWidth = btnWidth / modalWidth;
    const scaleHeight = btnHeight / modalHeight;
    const modalTranslateX = btnLeft - modalLeft;
    const modalTranslateY = btnTop - modalTop;

    return `translate(${modalTranslateX}px, ${modalTranslateY}px) scale(${scaleWidth}, ${scaleHeight})`;
}

/**
 * Update mini cart's html and icon's item number (fetch /mini-cart/)
 * @param { HTMLElement } miniCartIcon
 * @param { HTMLElement } miniCart
 */
function updateMiniCart(miniCartIcon, miniCart) {
    ajax('POST', '/wp/wp-admin/admin-ajax.php', { action: 'mini_cart_content' }, () => {}, (res) => {
        setHTML(miniCart, res);

        const itemNbr = Array.from(findChildren(miniCart, '.quantity > span'))
        .map(el => parseInt(el.textContent, 10))
        .reduce((acc, cur) => acc + cur, 0);

        setAttribute(miniCartIcon, 'data-items', itemNbr);
    });
}

export {
    onClick,
    onAllClick,
    getGrandParent,
    getModalInitialTransform,
    updateMiniCart,
    showFlashMessage,
    showNetworkErr,
    ajax
};
